<template>
    <v-progress-circular
        indeterminate
        :size="70"
        color="primary"
        style="display: flex; margin: 50px auto"
    />
</template>

<script>
export default {
    name: "Loader"
};
</script>

<style scoped></style>
